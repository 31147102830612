import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "react-bootstrap/Button"

import Header from "../components/header"
import Footer from "../components/footer"
import { Link } from "gatsby"

const Green = props => (
  <span className="green font-weight-bold">{props.children}</span>
)
const Download = props => (
  <a href={`/materials/${props.task}.zip`} className="font-weight-bold">
    ดาวน์โหลดโจทย์และข้อมูลทดสอบ ({props.task})
  </a>
)
const IndexPage = () => (
  <Layout>
    <SEO title="THACO | Tasks" name="tasks" />
    <Header page="tasks" />

    <section className="text-white" style={{ paddingTop: 150 }}>
      <div
        className="container text-center pb-5"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2>
          <span className="bg-dark px-2 py-1">โจทย์การแข่งขัน THACO 2020</span>
        </h2>
        <div id='tasks-download' className="sans text-left mb-0 mt-5">
          <ul className="text-left">
            <li>
              พบปะสังสรรค์ (reunion) <br />
              โดย <Green>อาภาพงศ์ จันทร์ทอง และ คณะฯ</Green>
              <br />
              <Download task="reunion" />
            </li>
            <br />
            <li>
              เกมลูกแก้ว (gridmarble) <br />
              โดย <Green>สิรวิชญ์ พงศ์นคินทร์</Green>
              <br />
              <Download task="gridmarble" />
            </li>
            <br />
            <li>
              อุปกรณ์แปลภาษา (translator) <br />
              โดย <Green>ณัฐพงศ์ ศรีวัฒนศักดิ์</Green>
              <br />
              <Download task="translator" />
            </li>
            <br />
            <li>
              จัดแสดงรถยนต์ (photoshoot) <br />
              โดย <Green>อาภาพงศ์ จันทร์ทอง</Green>
              <br />
              <Download task="photoshoot" />
            </li>
            <br />
            <li>
              ผีถ้วยแก้ว (ouija) <br />
              โดย <Green>ภูมิพัฒน์ ชัยประเสริฐสุด</Green>
              <br />
              <Download task="ouija" />
            </li>
            <br />
            <li>
              อภิมหาสะพานเชื่อม (superbridge) <br />
              โดย <Green>ณัฐพงศ์ ศรีวัฒนศักดิ์</Green>
              <br />
              <Download task="superbridge" />
            </li>
          </ul>
        </div>
        <h3 className="mt-5 text-rainbow">
          ขอขอบคุณผู้มีส่วนร่วมในการจัดเตรียมการแข่งขัน
        </h3>
        <div
          className="sans text-left mb-0 mt-5"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h4>ผู้ร่วมแต่งโจทย์</h4>
          <p className="mb-1 green">อาภาพงศ์ จันทร์ทอง</p>
          <p className="mb-1 green">ณัฐพงศ์ ศรีวัฒนศักดิ์</p>
          <p className="mb-1 green">ศุภกร กิจวัฒนาชัย</p>
          <p className="mb-1 green">ทัตต์ สังขะวิชัย</p>
          <p className="mb-1 green">ภูมิพัฒน์ ชัยประเสริฐสุด</p>
          <p className="mb-1 green">มัตตัญญู ตั้งเง็กกี่</p>
          <p className="mb-1 green">ธนภูมิ เหล่าอรุณ</p>
          <p className="mb-1 green">พนธกร ประเสริฐสุข</p>
          <p className="mb-1 green">กฤติธี รัชนันท์</p>
          <br />
          <h4>ผู้สร้างข้อมูลทดสอบ</h4>
          <p className="mb-1 green">วิสิฐ ภัทรนุธาพร</p>
          <p className="mb-1 green">อาภาพงศ์ จันทร์ทอง</p>
          <p className="mb-1 green">ณัฐพงศ์ ศรีวัฒนศักดิ์</p>
          <br />
          <h4>คณะกรรมการคัดเลือกและปรับแต่งโจทย์</h4>
          <p className="mb-1 green">อาภาพงศ์ จันทร์ทอง</p>
          <p className="mb-1 green">วิสิฐ ภัทรนุธาพร</p>
          <p className="mb-1 green">พิมพ์รัก อภิรัชตานนท์</p>
          <p className="mb-1 green">พนธกร ประเสริฐสุข</p>
          <p className="mb-1 green">กฤติธี รัชนันท์</p>
          <p className="mb-1 green">ธนภูมิ เหล่าอรุณ</p>
          <p className="mb-1 green">สัณห์ชยพล ลิ่วเกษมศานต์</p>
          <p className="mb-1 green">ธนดล ชมภูจันทร์</p>
          <br />
          <h4>ฝ่ายเทคนิค</h4>
          <p className="mb-1 green">นพกร จิระวรานันท์</p>
          <p className="mb-1 green">ปรเมศวร์ วัฒนประสาน</p>
          <br />
          <h4>ฝ่ายออกแบบ</h4>
          <p className="mb-1 green">ปรเมศวร์ วัฒนประสาน</p>
          <br />
          <h4>ฝ่ายประชาสัมพันธ์</h4>
          <p className="mb-1 green">ปรเมศวร์ วัฒนประสาน</p>
          <p className="mb-1 green">ณัฐพงศ์ ศรีวัฒนศักดิ์</p>
          <p className="mb-1 green">พงศพล พงศาวกุล</p>
          <p className="mb-1 green">พศวัต สุขใส</p>
          <br />
          <h4>ที่ปรึกษาผู้ทรงคุณวุฒิ</h4>
          <p className="mb-1 green">ผศ.ดร.จิตร์ทัศน์ ฝักเจริญผล</p>
          <p className="mb-1 green">ผศ.ดร.นัทที นิภานันท์</p>
          <p className="mb-1 green">ดร.วชิราวุธ ธรรมวิเศษ</p>
          <p className="mb-1 green">ผนวกเดช สุวรรณทัต</p>
        </div>
        <h4 className="mt-5 text-rainbow">
          และขอขอบคุณผู้เข้าแข่งขันทุกท่าน
          ที่ร่วมทำให้การแข่งขันนี้สำเร็จอย่างสมบูรณ์
        </h4>
      </div>
    </section>
    <Footer />
  </Layout>
)

export default IndexPage
